import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';

const NotFoundPage = () => (
    <Layout>
        <h1>404 Seite konnte leider nicht gefunden werden</h1>
        <Link to="/">Bring mich zur Startseite</Link>
    </Layout>
);

export default NotFoundPage;
